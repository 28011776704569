<template>
    <div class="page">
        <Aside />
        <div class="content">
            <div class="content-box">
                <div class="content-header">修改登录密码</div>
                <div class="content-detail">
                    <a-form-model ref="form" :model="form" :rules="formRules" size="small">
                        <a-form-model-item label="当前账号" class="account-label">
                            <div class="account">{{userInfo ? userInfo.account : ''}}</div>
                        </a-form-model-item>
                        <a-form-model-item label="旧密码" prop="old_password">
                            <a-input v-model="form.old_password" class="w260"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="新密码" prop="password">
                            <a-input v-model="form.password" class="w260"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="确认密码" prop="confirm_password">
                            <a-input v-model="form.confirm_password" class="w260"></a-input>
                        </a-form-model-item>
                    </a-form-model>
                </div>
                <div class="content-btn">
                    <a-button type="primary" size="small" class="confirm-btn btn" @click.native="editPass" :disabled="isDisable">确认</a-button>
                    <a-button size="small" class="cancel-btn btn" @click.native="$router.push('/')" :disabled="isDisable">取消</a-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Aside from "./components/aside";
import {checkPassword} from "@/libs/rules";
import cookies from "@/libs/util.cookies.js";
import {editUserPassword} from "@/api/data.js";
export default {
    components:{Aside},
    data(){
        return {
            form:{
                oldPassword:'',
                password:'',
                confirmpassword:'',
            },
            formRules:{
                old_password:[{required: true, message: '请输入旧密码', trigger: 'blur' },
                {validator: checkPassword, trigger: 'blur'}],
                password:[{required: true, message: '请输入新密码', trigger: 'blur' },
                {validator: checkPassword, trigger: 'blur'}],
                confirm_password:[{required: true, message: '请输入确认密码', trigger: 'blur' },
                {validator: checkPassword, trigger: 'blur'}],
            },
            isDisable:false,
        }
    },
    computed:{
        userInfo(){
            return this.$store.state.userinfo;
        }
    },
    methods:{
        editPass(){
            this.$refs.form.validate((valid) => {
                if(valid){
                    if(this.form.password === this.form.old_password){
                        this.$message.warning('新密码和旧密码相同，请修改');
                        return
                    }
                    if(this.form.confirm_password !== this.form.password){
                        this.$message.warning('确认密码与新密码不同，请重新输入');
                        return 
                    }
                    this.isDisable = true;
                    editUserPassword(this.form).then(data => {
                        if(data.code===1){
                            this.$message.success('成功修改密码，请重新登录');
                            cookies.remove('token');
                            this.$router.push( {path: '/login'} );
                        }
                        this.isDisable = false;
                    }).catch(() => {
                        this.isDisable = false;
                    })
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.content-detail{
    ::v-deep .ant-form-item{
        display:flex;
        .ant-form-item-label{
            width:108px;
            padding:0;
            > label{
                color:#515151;
            }
        }
        .ant-form-item-control-wrapper{
            margin-left:60px !important;
        }
        .ant-form-item-with-help{
            margin-bottom: 0;
        }
    }
    .account{
        color:#367BF5;
    }
    .w260{
        width:260px;
    }
}
.content-btn{
    margin:50px 0 0 168px;
    .btn{
        padding:0 30px;
        font-size: 14px;
        line-height: 34px;
        height:34px;
        border-radius: 4px;
    }
    .confirm-btn{
        background:#367BF5;
    }
    .cancel-btn{
        background: #6B6B6B;
        color: #EDEDED;
        margin-left:50px;
    }
}
</style>